<template>
  <v-container class="bg-section mt-16 mb-16" fluid>
    <v-row class="justify-center align-center mb-6">
      <v-col cols="12">
        <div class="mx-16 text-center">
          <h2 class="mr-md-14 mb-6 mx-md-16">
            Funcionalidades que simplificarán tus procesos
          </h2>
          <p class="mx-md-16">
            Gestiona tu negocio de principio a fin sin salir de Acist. Todo
            integrado. Todo accesible. Todo en tiempo real.
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="justify-center align-center center-content mb-xs-16 mb-16">
        <IconCard
            :title="'Visualiza el estado de tus ventas'"
            :subtitle="'Vigila tu nivel de gastos mes a mes'"
            :img="'cardIcon'" 
        />
      </v-col>
    </v-row>
    <v-row class="justify-center align-center mt-md-n8 mt-xs-16 mt-sm-16">
      <v-col cols="12" :md="4" class="center-content mt-n16"> 
        <div class="mb-16 pb-8">
            <IconCard
                :title="'Crea facturas en poco tiempo'"
                :subtitle="'Podrás crear tus facturas y enviarselas a tus clientes al instante'"
                :img="'invoiceIcon'" 
            />
        </div>
        <div>
            <IconCard
                :title="'Integra tu información bancaria'"
                :subtitle="'Conecta con tus bancos de forma segura'"
                :img="'bankIcon'" 
            />
        </div>
      </v-col>
      <v-col  cols="12" :md="4" class="center-content">
        <v-img
          :src="require('@/assets/function.png')"
          contain
          class="mt-16"
          height="500"
        />
      </v-col>
      <v-col  cols="12" :md="4" class="center-content mt-n16"> 
        <div class="mb-16 pb-8">
            <IconCard
                :title="'Envía múltiples facturas a la vez'"
                :subtitle="'Olvídate de tener que enviar las facturas una a una. '"
                :img="'invoice2Icon'" 
            />
        </div>
        <div>
            <IconCard
                :title="'Concilia tus movimientos'"
                :subtitle="'Realiza la conciliación bancaria de forma automática '"
                :img="'bank2Icon'" 
            />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import IconCard from '../IconCard.vue'
export default {
  name: "Functionalities",

  components: {
      IconCard
    },
  data: () => ({}),
};
</script>

<style scoped>
.icon {
  position: absolute;
}
.center-content {
  text-align: -webkit-center;
}
</style>
