import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#424242',
        primaryLight: '#F96F6F',
        primaryLint: '#FFE7E7',
        secondary: '#424242',
        accent: '#6271FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        text: '#2F2E4E'
      },
    },
  },
});
