<template>
  <v-container class="bg-section mt-16 mb-16" fluid>
    <v-row class="justify-center align-center mb-14">
      <v-col cols="11" :md="5">
        <v-img
          :src="require('@/assets/team.png')"
          contain
          class="mt-8"
          height="400"
        />
      </v-col>
      <v-col cols="12" :md="6" class="mt-4">
        <div class="mx-4 mx-md-16 mb-14">
          <h2 class="mr-md-14 mb-6">Coordina quién, cuándo y cómo</h2>
          <p class="">
            Desde el portal del empleado, tus trabajadores podrán acceder a sus
            documentos, registrar horas trabajadas y solicitar ausencias, pero
            tú siempre controlarás lo que pueden y no pueden ver.
          </p>
        </div>
        <v-row class="ml-md-16 mx-md-16 text-center">
          <v-col cols="12" :sm="4">
            <v-img
              :src="require('@/assets/file-ident.svg')"
              contain
              class="mt-8"
              height="80"
            />
            <h5>CONTRATOS</h5>
          </v-col>
          <v-col cols="12" :sm="4"> 
              <v-img
              :src="require('@/assets/file-nom.svg')"
              contain
              class="mt-8"
              height="80"
            />
            <h5>NÓMINAS</h5>
          </v-col>
          <v-col cols="12" :sm="4"> 
              <v-img
              :src="require('@/assets/file-doc.svg')"
              contain
              class="mt-8"
              height="80"
            />
            <h5>DOCUMENTOS</h5>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Team",

  data: () => ({}),
};
</script>

<style scoped></style>
