import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/consultants',
    name: 'Consultants',
    component: () => import(/* webpackChunkName: "Consultants" */ '../views/Consultants.vue')
  },
  {
    path: '/facturacion',
    name: 'Facturacion',
    component: () => import(/* webpackChunkName: "Facturacion" */ '../views/Facturacion.vue')
  },{
    path: '/contabilidad',
    name: 'Contabilidad',
    component: () => import(/* webpackChunkName: "Contabilidad" */ '../views/Contabilidad.vue')
  },{
    path: '/inventarios',
    name: 'Inventarios',
    component: () => import(/* webpackChunkName: "Inventarios" */ '../views/Inventarios.vue')
  },{
    path: '/compras',
    name: 'Compras',
    component: () => import(/* webpackChunkName: "Compras" */ '../views/Compras.vue')
  },{
    path: '/proyectos',
    name: 'Proyectos',
    component: () => import(/* webpackChunkName: "Proyectos" */ '../views/Proyectos.vue')
  },{
    path: '/portal',
    name: 'Portal',
    component: () => import(/* webpackChunkName: "Portal" */ '../views/Portal.vue')
  },{
    path: '/all',
    name: 'All',
    component: () => import(/* webpackChunkName: "All" */ '../views/All.vue')
  },{
    path: '/prices',
    name: 'Prices',
    component: () => import(/* webpackChunkName: "Prices" */ '../views/Prices.vue')
  },{
    path: '/resources',
    name: 'Resources',
    component: () => import(/* webpackChunkName: "Resources" */ '../views/Resources.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
