<template>
  <v-container class="bg-section mt-16 mb-16" fluid>
      <v-row class="justify-center align-center mb-14">
        <v-col class="mx-md-12" cols="10" :md="5">
              <div class="ml-6 mb-16">
                  <h2 class="mr-14 mb-6 white--text">Reemplaza procesos manuales</h2>
                  <p class="mr-14 pb-8 white--text">Con las automatizaciones que te propone Acist, ahorras tiempo y tu empresa es mucho más productiva.</p>
              </div>
              <!-- Card -->
              <Card 
                :img="'factura'"
                :title="'Envía y gestiona tus facturas'" 
                :subtitle="'Elimina el error humano en la generación de tus facturas y olvídate de enviar facturas incompletas o con cálculos erróneos.'"
              />
          </v-col>
          <v-col cols="10" :md="5" class="mt-4 mx-md-12">
              <!-- Card -->
              <Card 
                :img="'analisis'"
                :title="'Lleva tu contabilidad al día'"
                :subtitle="'Al ser una herramienta totalmente integrada, los datos de tus facturas y gastos se incorporarán automáticamente '"
              />
              <!-- Card -->
              <Card 
                :img="'contabilidad'"
                :title="'Controla tus gastos de empresa'"
                :subtitle="'Gestiona paso a paso y de forma automática la introducción de los datos de tus gastos a tu contabilidad'"
              />
          </v-col>
          
      </v-row>
  </v-container>
</template>

<script>
import Card from '../Card.vue'
  export default {
    name: 'Processes',

    components: {
      Card,
    },
    data: () => ({
    }),
  }
</script>

<style scoped>
.bg-section {
    background-color: #141e56;
}
</style>
