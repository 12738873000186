<template>
  <v-container class="bg-section mb-md-16" fluid>
    <v-row class="justify-center align-center mb-14">
      <v-col cols="12" :md="6" class="mt-4">
        <div class="mx-4 ml-md-16 mb-14">
          <h2 class="b-6 ml-md-16 pl-md-16">
            Todos los aspectos de tu proyecto aquí
          </h2>
          <p class="ml-md-16 mt-8 pl-md-16">
            Ya sea organizar un team building o diseñar un software
            revolucionario, los proyectos de Acist te dan las herramientas para
            gestionar cualquier tarea de principio a fin.
          </p>
        </div>
      </v-col>
      <v-col cols="12" :md="6" class="cont-img">
        <v-img
          :src="require('@/assets/man-accounting.png')"
          contain
          height="440"
          class="ml-12"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HomeProject",

  data: () => ({}),
};
</script>

<style scoped>
.cont-img {
  text-align-last: left;
}
</style>
