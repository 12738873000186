<template>
  <div class="mx-6 mt-16">
    <v-card class="mx-auto" min-height="290px">
      <v-img :src="require('@/assets/rectangle.svg')" height="6" />
      <v-img
        :src="require('@/assets/icons/'+img+'.png')"
        contain
        class="mt-8"
        height="70"
      />
      <div class="mx-16 mt-10 mb-16 text-center">
        <h5 class="mb-1">{{title}}</h5>
        <h6>
          {{subtitle}}
        </h6>
      </div>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'Card',

    props: ['title', 'subtitle', 'img'],
    data: () => ({
    }),
  }
</script>
