<template>
  <v-container class="pa-0" fluid>
    <Banner />
    <Processes />
    <Digitization />
    <Accounting />
    <Functionalities />
    <Control />
    <Team />
    <HomeProject />
    <HomeControl />
  </v-container>
</template>

<script>
  import { mapMutations, mapState } from 'vuex'
  import Banner from '../components/Banner.vue'
  import Processes from '../components/sections/Processes.vue'
  import Digitization from '../components/sections/Digitization.vue'
  import Accounting from '../components/sections/Accounting.vue'
  import Functionalities from '../components/sections/Functionalities.vue'
  import Control from '../components/sections/Control.vue'
  import Team from '../components/sections/Team.vue'
  import HomeProject from '../components/sections/HomeProject.vue'
  import HomeControl from '../components/sections/HomeControl.vue'

  export default {
    name: 'Home',

    components: {
      Banner,
      Processes,
      Digitization,
      Accounting,
      Functionalities,
      Control,
      Team,
      HomeProject,
      HomeControl
    },
    computed: {
      ...mapState(['navBarMain'])
    },
  }
</script>
