<template>
  <v-container class="bg-section mb-16" fluid>
    <v-row class="justify-center align-center mb-14">
      <v-col cols="12" :md="6" class="cont-img">
        <v-img
          :src="require('@/assets/home-control.png')"
          contain
          height="380"
          class="mr-12"
        />
      </v-col>
      <v-col cols="12" :md="6" class="mt-4">
        <div class="mr-md-16 mb-14">
          <h2 class="b-6 mr-md-16 pr-md-16">
            Controla la entrada y salida de productos en tiempo real
          </h2>
          <p class="mr-md-16 mt-8 pr-md-16">
            Con Acist tu inventario tendrá muchas más utilidades de las que
            crees. Si realmente queremos que esta sea una herramienta clave,
            debemos ser capaces de sacarle el máximo partido
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HomeControl",

  data: () => ({}),
};
</script>

<style scoped>
.cont-img {
  text-align-last: right;
}
</style>
