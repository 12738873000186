<template>
  <v-container class="bg-section mt-md-16 mb-md-16" fluid>
    <v-row class="justify-center align-center mb-14">
      <v-col cols="12" :md="6" class="mt-4">
        <div class="mx-md-16 mb-14">
          <h2 class="mr-md-14 mb-6 mx-md-16">Ten el control sobre tu negocio</h2>
          <p class="mx-md-16">
            Con Acist puedes realizar todos los informes que necesites para
            valorar en cada momento la gestión y las posibilidades de tu
            negocio. Planifica cada paso, visualiza la meta y calcula la
            rentabilidad de tus trabajos.
          </p>
        </div>
        <div class="ml-md-16">
          <v-list disabled class="mt-6 ml-md-10">
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, i) in ecosystem" :key="i">
                <v-list-item-icon class="mr-4">
                  <div color="primaryLight">
                    <v-img
                      :src="require('@/assets/icons/point.svg')"
                      contain
                      height="20"
                    />
                  </div>
                </v-list-item-icon>
                <v-list-item-content style="display: inline">
                  <p>
                    {{ item.text }} <b>{{ item.bold }}</b>
                  </p>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-col>
      <v-col cols="12" :md="6">
        <v-img
          :src="require('@/assets/control.svg')"
          contain
          class="mt-8"
          height="500"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Control",

  data: () => ({
    ecosystem: [
      {
        text: "Solución sencilla a los proyectos más complejos",
        bold: "",
      },
      {
        text: "Factura por hora, por miembro del equipo o categoría",
        bold: "",
      },
      {
        text: "Crea todos los almacenes que quieras",
        bold: "",
      },
      {
        text: "Otorga diferentes tipos de privilegios entre tus usuarios",
        bold: "",
      },
    ],
  }),
};
</script>

<style scoped></style>
