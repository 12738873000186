<template>
  <v-app>
    <!-- Menu -->
    <div
      class="myNavbar"
      :class="[
        scrollPosition > 50 ? 'add-shadow' : 'without-shadow',
        navBarMain == 0 ? 'bgLight' : 'bgDark',
      ]"
    >
      <v-navigation-drawer v-model="sidebar" app>
        <v-list>
          <v-list-item
            v-for="item in menuItemsFirst"
            :key="item.title"
            :to="item.path"
          >
            <v-list-item-content>{{ item.title }}</v-list-item-content>
          </v-list-item>
          <div class="bottomLine mx-4 my-5"></div>
          <v-list-item
            v-for="item in menuItemsSec"
            :key="item.title"
            @click="showSubMenuMob = !showSubMenuMob"
          >
            <v-list-item-content style="display: inline">{{ item.title }} <v-icon class="ml-1"> mdi-chevron-down </v-icon> </v-list-item-content>
          </v-list-item>
          <div v-if="!showSubMenuMob" @mouseleave="showSubMenu = true" class="px-6 mt-6 mb-5 ">
            <v-list-item
              v-for="o in options"
              :key="o.title"
              :to="o.path"
              @click.native="scrollToTop()"
            >
              <v-list-item-content style="font-size: 13px">{{ o.title }}</v-list-item-content>
            </v-list-item>
          </div>

          <v-list-item
            v-for="item in menuItems"
            :key="item.title"
            :to="item.path"
          >
            <v-list-item-content>{{ item.title }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-toolbar app>
        <v-toolbar-title>
          <router-link to="/" tag="span" style="cursor: pointer">
            <v-img
              v-if="navBarMain == 0 || scrollPosition > 50"
              :src="require('./assets/logo-main.svg')"
              contain
              width="130"
              class="ml-10"
            />
            <v-img
              v-else
              :src="require('./assets/logo-main-light.svg')"
              contain
              width="130"
              class="ml-10"
            />
          </router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <span class="hidden-lg-and-up">
          <v-icon
            :color="navBarMain == 0 || scrollPosition > 50 ? 'text' : 'white'"
            @click="sidebar = !sidebar"
            >mdi-menu</v-icon
          >
        </span>
        <v-toolbar-items class="hidden-md-and-down mr-10">
          <v-btn
            text
            class=""
            :color="navBarMain == 0 || scrollPosition > 50 ? 'text' : 'white'"
            v-for="item in menuItemsFirst"
            :key="item.title"
            :to="item.path"
            @click.native="scrollToTop()"
          >
            {{ item.title }}
          </v-btn>
          <div class="verticalLine mx-4 my-5"></div>
          <v-btn
            text
            class=""
            :color="navBarMain == 0 || scrollPosition > 50 ? 'text' : 'white'"
            @click="showSubMenu = !showSubMenu"
            v-for="item in menuItemsSec"
            :key="item.title"
            @mouseover="showSubMenu = false"
          >
            {{ item.title }}
            <v-icon class="ml-1"> mdi-chevron-down </v-icon>
          </v-btn>
          <div
            class="text-gray-darker mt-16 px-8 pt-6 pb-5 submenu"
            v-if="!showSubMenu"
            @mouseleave="showSubMenu = true"
          >
            <div class="triangleUp mt-n10"></div>
            <div v-for="o in options" :key="o" class="mb-2">
              <v-btn
                text
                class=""
                color="text"
                :to="o.path"
                @click.native="scrollToTop()"
              >
                {{ o.title }}
              </v-btn>
            </div>
          </div>
          <v-btn
            text
            class=""
            :color="navBarMain == 0 || scrollPosition > 50 ? 'text' : 'white'"
            v-for="item in menuItems"
            :key="item.title"
            :to="item.path"
            @click.native="scrollToTop()"
          >
            <!-- <v-icon>mdi-{{ item.icon }}</v-icon> -->
            {{ item.title }}
          </v-btn>
          <div class="text-center mt-3 ml-2">
            <v-btn rounded color="accent" dark> Probar GRATIS </v-btn>
          </div>
        </v-toolbar-items>
      </v-toolbar>
    </div>
    <!-- End Menu -->
    <v-main>
      <router-view />
    </v-main>
    <!-- Footer -->
    <div class="footer py-16">
      <v-row class="ml-10">
        <v-col cols="12" :md="7">
          <v-img
              :src="require('./assets/logo-main.svg')"
              contain
              width="130"
            />
            <h6>© 2022 Acist. All rights reserved.</h6>
        </v-col>
        <v-col cols="12" :md="5" class="contact">
          <h3 class="ml-2">Contacto</h3>
          <p class="ml-4">Jr. Jorge Chávez Nro. 1747 Dpto. 704, Breña - Lima</p>
          <p class="ml-4">+051 9825 09639</p>
          <br>
           
        </v-col>
      </v-row>
      <v-row class="ml-10">
        <hr class="mt-20 mb-20" />
        
      </v-row>
    </div>
  </v-app>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "App",

  data() {
    return {
      appTitle: "Awesome App",
      sidebar: false,
      showSubMenu: true,
      showSubMenuMob: true,
      scrollPosition: null,
      menuItemsFirst: [
        { title: "Empresas", path: "/", icon: "home" },
        { title: "Consultores", path: "/consultants", icon: "home" },
      ],
      menuItemsSec: [{ title: "Características" }],
      options: [
        { title: "Facturación", path: "/facturacion" },
        { title: "Contabilidad", path: "/contabilidad" },
        { title: "Inventarios", path: "/inventarios" },
        { title: "Proyectos", path: "/proyectos" },
        { title: "Compras", path: "/compras" },
        { title: "Portal de Proveedores", path: "/portal" },
        { title: "Todas las características", path: "/all" },
      ],
      menuItems: [
        { title: "Planes", path: "/prices", icon: "home" },
        { title: "Recursos", path: "/resources", icon: "home" },
      ],
    };
  },
  computed: {
    ...mapState(["navBarMain"]),
  },
  updated() {
    if (this.$route.path != "/consultants") {
      this.changeNavBar(0);
    } else {
      this.changeNavBar(1);
    }
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    ...mapMutations(["changeNavBar"]),
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
.v-btn:before {
  background-color: transparent;
  border-bottom: solid red;
}
.v-toolbar {
  flex: initial;
}
.verticalLine {
  border-left: solid #9797a7;
}
.bottomLine {
  border-bottom: solid #9797a7;
}
.myNavbar {
  position: sticky;
  top: 0;
  z-index: 10;
}
span.v-btn__content:before {
  border-bottom: solid;
}
.theme--light.v-btn--active:before,
.theme--light.v-btn--active:hover:before {
  opacity: 1;
}
.theme--light.v-toolbar.v-sheet {
  background-color: transparent;
}
.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none;
}
.without-shadow {
  box-shadow: none;
}
.add-shadow {
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
  background-color: white !important;
}
.bgLight {
  background-color: white;
}
.bgDark {
  background-color: #141e56;
}
.submenu {
  position: fixed;
  margin-left: 18%;
  background-color: #fbfbfb;
  border-radius: 0.6rem;
  box-shadow: 0px 0px 44px 4px rgb(0 0 0 / 16%);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.triangleUp {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 30px solid #fbfbfb;
  position: absolute;
}
/* .v-icon.v-icon.v-icon--link {
    color: white;
  } */

/* Footer */
.footer {
  background-color: #FAFAFB;
}
.contact {
  border-left: solid #647b9c;
}
</style>
