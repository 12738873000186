<template>
  <v-card max-width="370px">
    <v-img
      :src="require('@/assets/icons/'+img+'.png')"
      contain
      class="icon mt-n7"
      height="60"
      width="100%"
    />
    <div class="mx-8 text-center pb-6">
      <h5 class="mb-1 pt-12">{{title}}</h5>
      <h6>{{subtitle}}</h6>
    </div>
  </v-card>
</template>

<script>
  export default {
    name: 'IconCard',

    props: ['title', 'subtitle', 'img'],
    data: () => ({
    }),
  }
</script>

<style scoped>
.icon {
  position: absolute;
}
</style>
