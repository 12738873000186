<template>
  <v-container>
    <v-row class="mx-sm-16">
      <v-col cols="12" :md="6" class="mt-16">
        <h1 class="mx-4 mb-12 mt-12 ">
          Gestiona tu empresa desde cualquier lugar 
        </h1>

        <p class="subheading font-weight-regular">
          Controla tu negocio desde la mejor plataforma y sé más eficiente:
          <v-list disabled class="mt-6">
            <v-list-item-group
              color="primary"
            >
              <v-list-item
                v-for="(item, i) in ecosystem"
                :key="i"
              >
                <v-list-item-icon class="mr-3">
                  <div color="primaryLight">
                    <v-img
                      :src="require('../assets/icons/point.svg')"
                      contain
                      height="20"
                    />
                  </div>
                </v-list-item-icon>
                <v-list-item-content style="display:inline;">
                  <p>{{item.text}} <b>{{item.bold}}</b> </p>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </p>
        <div class="mt-12 ml-2">
          <v-row>
            <div class="text-center">
              <v-btn
                rounded
                x-large
                class="mainButton"
                dark
              >
                Probar Gratis
              </v-btn>
            </div>
            <div class="text-center ml-10">
              <v-btn
                rounded
                x-large
                color="primaryLint"
                class="red--text"
                dark
              >
                Contactarme 
              </v-btn>
            </div>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" :md="6" class="hidden-md-and-down">
        <v-img
          :src="require('../assets/main-banner.png')"
          class="my-3 mt-16"
          contain
          height="560"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Banner',

    data: () => ({
      ecosystem: [
        {
          text: 'Simplifica la gestión de tu ',
          bold: 'empresa',
        },
        {
          text: 'Digitaliza y automatiza tus ',
          bold: 'tareas',
        },
        {
          text: 'Agiliza los procesos de ',
          bold: 'contabilidad',
        },
      ],
    }),
  }
</script>

<style scoped>
div.v-list-item__content {
  padding: 0px;
}
</style>
