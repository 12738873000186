<template>
  <v-container class="bg-section mt-16 mb-16" fluid>
    <v-row class="justify-center align-center mb-14">
      <v-col cols="12" :md="6" class="mt-4">
        <div class="mx-16 mb-14">
          <h2 class="mr-md-14 mb-6 mx-md-16">Reemplaza procesos manuales</h2>
          <p class="mx-md-16">
            Genera tus facturas periódicas. Podrás crear tus facturas y
            enviarselas a tus clientes al instante, desde cualquier lugar y
            desde cualquier dispositivo. No esperes a estar en la oficina para
            hacerlo.
          </p>
        </div>
        <div class="text-center">
          <v-btn rounded large class="mainButton" dark> Conocer más </v-btn>
        </div>
      </v-col>
      <v-col cols="12" :md="6">
        <v-img
          :src="require('@/assets/digitization.png')"
          contain
          class="mt-8"
          height="500"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Digitization",

  data: () => ({}),
};
</script>

<style scoped></style>
