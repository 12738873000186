<template>
  <v-container class="bg-section mt-16 mb-16" fluid>
    <v-row class="justify-center align-center mb-14">
      <v-col cols="12" :md="6">
        <v-img
          :src="require('@/assets/account.png')"
          contain
          class="mt-8"
          height="400"
        />
      </v-col>
      <v-col cols="12" :md="6" class="mt-4">
        <div class="mx-16 mb-14">
          <h2 class="mr-md-14 mb-6 mx-md-16">
            Contabilidad moderna: Nos encargamos de las cuentas por ti
          </h2>
          <p class="mx-md-16">
            Haz un seguimiento de tus finanzas en tiempo real y trabaja con
            informes contables precisos. Acist se puede conectar con las
            herramientas que ya utilizas para una gestión más eficiente e
            integrada de tu negocio.
          </p>
        </div>
        <div class="text-center">
          <v-btn rounded large class="mainButton" dark> Conocer más </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Accounting",

  data: () => ({}),
};
</script>

<style scoped></style>
